@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');

a {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  scroll-behavior: smooth;
  transition: all ease 0.3s;
}
::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  background: #ccc;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
  border: 2px solid #ddd;
  border-radius: 10px;
}
.slick-center { 
  transform: scale(1.08) 
}
.lh1 {
  -webkit-text-stroke: 2px black;
  
  
  
}
.lh2 {
  -webkit-text-stroke: 1px white;
  color: black;
  
}
.slide {
  transform: scale(0.2);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}